isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}



if (document.querySelectorAll("#btn-go-top").length) {


    let mybutton = document.querySelector("#btn-go-top");
    window.onscroll = function() { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    {
        document.querySelector("#btn-go-top").addEventListener("click", (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
                // passive: true,
            });
        });
    }
}

function checkMobile() {
    if (isMobile) {
        document.querySelector("body").classList.add("is--mobile");
        document.querySelector("body").classList.remove("is--desktop");
    } else {
        document.querySelector("body").classList.remove("is--mobile");
        document.querySelector("body").classList.add("is--desktop");
    }
}
checkMobile();

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let swiperScript = 0;

window.addEventListener("scroll", function(e) {

    if (document.querySelectorAll(".header").length) {
        var navbar = document.querySelectorAll(".header")[0];

        function addFixedMenu() {
            if (window.pageYOffset >= navbar.clientHeight + 450) {
                navbar.classList.add("fixed-header-scroll");
                document.querySelector("body").classList.add("menu-is-fixed");
            } else {
                navbar.classList.remove("fixed-header-scroll");
                document.querySelector("body").classList.remove("menu-is-fixed");
            }
        }

        if (document.body.clientHeight > (window.innerHeight + 600)) {
            addFixedMenu();
        }
    }
});



let slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
var slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}








function CloseLeftMenuMobile() {
    let navToggle = document.getElementById("nav-toggle");
    // navToggle.classList.toggle("open");
    if (navToggle.classList.contains("open")) {      
        document.querySelector(".navMain").classList.remove("open");
        document.querySelector("body").classList.remove("menu-open");
        navToggle.classList.remove("open");
    }
}

let closeMenuContainer = document.getElementById("nav-mobile-close");
let navToggleOpen = document.getElementById("nav-toggle--open");
const navItemToOpenA = document.querySelectorAll('.nav__level-1>.menu__item--has-children>a');
const navItemToOpenLi = document.querySelectorAll('.nav__level-1>.menu__item--has-children');

const navItemToOpenA2 = document.querySelectorAll('.nav__level-2>li>a');
const navItemToOpenLi2 = document.querySelectorAll('.nav__level-2>li');
let loginBtn = document.querySelector(".login__btn");
let loginWrapper = document.querySelector(".login__wrapper");
let loginList = document.querySelector(".login__list");
let mainSearchBtnH = document.getElementById("mainSearchBtn");
let formSearchH = document.querySelector(".headerForm");
let wrapperSearchH = document.getElementById("search-mobile-close");


function CloseSearchMobile() {
    if (mainSearchBtnH.classList.contains("open")) {
        formSearchH.classList.remove("open");
        mainSearchBtnH.classList.remove("open");
        wrapperSearchH.classList.remove("open");
    }
}
function CloseLoginMobile() {
    if (loginBtn.classList.contains("open")) {
        loginList.classList.remove("open");
        loginBtn.classList.remove("open");
    }
}

closeMenuContainer.addEventListener("click", function(e) {
    e.preventDefault();
    e.stopPropagation();
    CloseLeftMenuMobile()
})
navToggleOpen.addEventListener("click", function(e) {
    e.preventDefault();
    e.stopPropagation();
    CloseLeftMenuMobile();
})


//searchonMobile




mainSearchBtn.addEventListener("click", function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!mainSearchBtnH.classList.contains("open")) {
        formSearchH.classList.add("open");
        mainSearchBtnH.classList.add("open");
        wrapperSearchH.classList.add("open");
        CloseLeftMenuMobile();
        CloseLoginMobile();      

    } else {
        CloseSearchMobile(); 
    }
})

wrapperSearchH.addEventListener("click", function(e) {
    e.preventDefault();
    e.stopPropagation();
    CloseSearchMobile()

})
//




document.querySelector("#nav-toggle").addEventListener("click", function(e) {
    e.preventDefault();
    e.stopPropagation();
    CloseSearchMobile();
    this.classList.toggle("open");
    if (this.classList.contains("open")) {
        document.querySelector("body").classList.add("menu-open");
        document.querySelector(".navMain").classList.add("open");
    } else {
        // if (document.querySelector(".nav ul.open")) {
        //     slideUp(document.querySelector(".nav ul.open"), 300);
        // }
        document.querySelector(".navMain").classList.remove("open");
        document.querySelector("body").classList.remove("menu-open");
    }

    CloseLoginMobile();
})



loginBtn.addEventListener("click", function() {
    if (this.classList.contains("open")) {
        loginList.classList.remove("open");
        this.classList.remove("open");
    } else {

        loginList.classList.add("open");
        this.classList.add("open");
    }

    if (document.querySelector("#nav-toggle").classList.contains("open")) {
        if (document.querySelector(".navMain.open")) {
            document.querySelector(".navMain.open").classList.remove("open");
        }
        // slideUp(document.querySelector(".nav"), 300);
        document.querySelector("body").classList.remove("menu-open");
        document.querySelector("#nav-toggle").classList.remove("open")
    }
    CloseSearchMobile();

});

// previousElementSibling
if (window.ontouchstart !== undefined) {
    document.querySelector("body").classList.add("menu-mobile");

    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenLi[i].addEventListener("click", function(e) {

            // e.preventDefault();
            // e.stopPropagation();
            let submenu = this.querySelector('.nav__level-2');

            if (submenu.classList.contains("m-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m-open");
            } else {
                if (document.querySelector(".nav ul.m-open")) {
                    slideUp(document.querySelector(".nav ul.m-open"), 300);
                    document.querySelector(".nav ul.m-open").classList.remove("open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m-open");
            }
            if (document.querySelector(".nav ul.m2-open")) {
                slideUp(document.querySelector(".nav ul.m2-open"), 300);
                document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
            }

        });
    }
    for (var i = 0; i < navItemToOpenA2.length; i++) {
        navItemToOpenLi2[i].addEventListener("click", function(e) {
            e.stopPropagation();

            let submenu = this.parentNode.querySelector(".nav__level-3");

            if (submenu.classList.contains("m2-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m2-open");
            } else {
                if (document.querySelector(".nav ul.m2-open")) {
                    slideUp(document.querySelector(".nav ul.m2-open"), 300);
                    document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m2-open");
            }
        });
    }


    // $(".team__item").click(function(e) {
    //     if ($(this).hasClass("open")) {
    //         $(this).removeClass("open");
    //     } else {
    //         $(".team__item.open").removeClass("open");
    //         $(this).addClass("open");
    //     }
    // })

} else {
    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenA[i].addEventListener("click", function(e) {
            // e.preventDefault();
            // e.stopPropagation();
        });
        navItemToOpenA2[i].addEventListener("click", function(e) {
            // e.preventDefault();
            // e.stopPropagation();
        });
    }
    for (var i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseenter", function(e) {
            document.body.classList.add("menu-d-open");


            this.querySelector('.nav__level-2').style.display = "block";

            this.querySelector('.nav__level-2>li:first-child>a').classList.add('active');

            if (this.querySelector('.nav__level-2>li:first-child .nav__level-3')) { this.querySelector('.nav__level-2>li:first-child .nav__level-3').classList.add("open"); }

            if (this.getAttribute("data-img")) {
                this.querySelector('.nav__img img').src = this.getAttribute("data-img");
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseleave", function(e) {
            this.querySelector('.nav__level-2').style.display = "none";
            this.classList.remove('active');

            document.body.classList.remove("menu-d-open");
        });
    }
    for (var i = 0; i < navItemToOpenLi2.length; i++) {
        navItemToOpenLi2[i].addEventListener("mouseenter", function(e) {
            if (document.querySelector('.nav__level-3')) { document.querySelector('.nav__level-3').classList.remove("open"); }
            if (document.querySelector('.nav a.active')) {
                document.querySelector('.nav a.active').classList.remove("active");
            }
            if (this.querySelector('.nav__level-3')) {
                this.querySelector('.nav__level-3').classList.add("open");
            }

            if (this.getAttribute("data-img")) {

                this.closest(".menu__item--has-children").querySelector(".nav__img img").src = this.getAttribute("data-img");
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi2.length; i++) {
        navItemToOpenLi2[i].addEventListener("mouseleave", function(e) {
            if (this.querySelector('.nav__level-3')) {
                this.querySelector('.nav__level-3').classList.remove("open");
            }
            this.classList.add('remove');
        });
    }

    loginWrapper.addEventListener("mouseenter", function() {
        if (!loginBtn.classList.contains("open")) {
            loginList.classList.add("open");
            loginBtn.classList.add("open");
        }

    });
    loginWrapper.addEventListener("mouseleave", function() {
        if (loginBtn.classList.contains("open")) {
            loginList.classList.remove("open");
            loginBtn.classList.remove("open");
        }

    });
    // for (var i = 0; i < teamItemList.length; i++) {
    //     teamItemList[i].addEventListener("mouseenter", function(e) {
    //         this.classList.add("open");
    //     });
    // }
    // for (var i = 0; i < teamItemList.length; i++) {
    //     teamItemList[i].addEventListener("mouseout", function(e) {
    //         this.classList.remove("open");
    //     });
    // }       
}






function accordion(accordion) {
    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__btn");

    accordionBtn.addEventListener("click", function(e) {
        e.preventDefault();
        if (accordion.classList.contains("active")) {
            this.classList.remove("active");
            this.setAttribute('aria-expanded', false);
            accordionContent.classList.remove("active");
            accordionContent.setAttribute('aria-hidden', true);
            slideUp(accordionContent);
            accordion.classList.remove("active");
        } else {
            this.classList.add("active");
            this.setAttribute('aria-expanded', true);
            accordion.classList.add("active");
            accordionContent.setAttribute('aria-hidden', false);
            accordionContent.classList.add("active");
            slideDown(accordionContent);
        }
    })
}
Array.prototype.map.call(document.querySelectorAll(".accordion"), function(item) { accordion(item) });


//TAB
function tabsRun(wrapper) {
    var tabs = wrapper.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function 
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = wrapper.querySelectorAll('[role=tab]'); //get tabs again as a different variable 
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab       
        var tabPanelToOpen = el.target.getAttribute('aria-controls');
        if (!tabPanelToOpen) {

            tabPanelToOpen = el.target.close("button").getAttribute('aria-controls');
        } //get the aria-controls value of the tab that was clicked
        var tabPanels = wrapper.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");
        } //hide all tabpanels

        wrapper.querySelector("#" + tabPanelToOpen).setAttribute("aria-hidden", "false");
        wrapper.querySelector("#" + tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
}
Array.prototype.map.call(document.querySelectorAll(".tabs"), function(item) { tabsRun(item) });


let touchstartX = 0;
let touchendX = 0;

if (document.querySelector('.elements__right')) {
    const gestureZone = document.querySelector('.elements__right');


    gestureZone.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function(event) {
        touchendX = event.changedTouches[0].screenX;
        handleGesture();
    }, false);
}

function handleGesture() {
    if (touchendX >= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click();
        }
    }
    if (touchendX <= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling) { document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click() }
    }

};





(function() {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {

            modalTrigger[i].onclick = function(e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);

                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function() {
                var modalWindow = this.parentNode.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function() {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);



}());


if (document.getElementById("hero__move")) {

    let heroLeft = (document.querySelector(".hero-clipping-wrapper").getBoundingClientRect()).left;
    let heroRight = document.querySelector(".hero__right").clientWidth;


    if (window.innerWidth > 576) {
        document.querySelector(".hero-clipping-wrapper").style.width = "50%";
    } else {
        document.querySelector(".hero-clipping-wrapper").style.width = "100px";
    }

    let isDown = false;
    let offsetLeft = 0;

    document.querySelector("#hero__move").addEventListener("mousedown", function(event) {
        isDown = true;
        offsetLeft = event.pageX - heroLeft;

    }, true);
    document.addEventListener('mouseup', function() {
        isDown = false;
    }, true);
    document.addEventListener('mousemove', function(event) {
        event.preventDefault();
        if (isDown) {
            offsetLeft = event.pageX - heroLeft;
            if (offsetLeft > 0 && offsetLeft < heroRight) { document.querySelector(".hero-clipping-wrapper").style.width = offsetLeft + "px"; }
        }
    }, true);

    document.querySelector(".hero__right").addEventListener("touchmove", function(event) {
        offsetLeft = event.touches[0].clientX;
        document.querySelector(".hero-clipping-wrapper").style.width = offsetLeft + "px";

    });


    // document.querySelector("#hero__move").addEventListener("touchmove", function(event) {
    //     pointerX = event.touches[0].clientX - heroLeft;;
    //     pointerY = event.pageY;
    //     if (pointerX > 0) { document.querySelector(".hero-clipping-wrapper").style.width = pointerX + "px"; }

    // });


}


//ulubione

var http_requestX = false;

/*
data - dane do wysłania
callbackF - funkcja zwrotna
itemEl - elemnt na kórym wywołujemy funkcje zwrotną /opcjonalny
*/
function makeRequest(url = '/a/', method = 'POST', data, callbackF, itemEl) {

    http_requestX = false;

    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_requestX = new XMLHttpRequest();
        if (http_requestX.overrideMimeType) {
            http_requestX.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_requestX = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_requestX = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {}
        }
    }

    if (!http_requestX) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }

    http_requestX.onreadystatechange = function() { alertContents2(http_requestX, callbackF, itemEl) };
    http_requestX.open(method, url, true);
    http_requestX.send(data);
}

function alertContents2(http_requestX, functionName, itemEl) {
    try {
        if (http_requestX.readyState == 4) {
            if (http_requestX.status == 200) {


                functionName(http_requestX.responseText, itemEl);


            } else {

                alert('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {

    }
}

function favouriteCountCallbackFunction(data) {

    let heart = document.querySelector(".header__cartAmount");
    let heart2 = document.querySelector(".headerControls__heart");
    heart.innerHTML = data;


    (data > 0) ? heart2.classList.add('active'): heart2.classList.remove('active');
    (data > 0) ? heart.innerHTML = data: heart.innerHTML = "0";

    if (!data > 0 && document.getElementById("favourte--info")) {
        document.getElementById("favourte--info").style.display = "block";
    }

}



function favouriteCallbackFunction(data, el) {

    if (data == "ok") {

        el.classList.toggle("btn--heartAvtive");
        if (el.classList.contains("btn--heartAvtive")) {
            el.setAttribute("aria-label", "Usuń z ulubionych")  ;         
        }else{
            el.setAttribute("aria-label", "Dodaj do ulubionych");
        }


        if (el.querySelector("span")) {
            let txtEls = el.querySelectorAll("span");

            txtEls[0].classList.toggle("hide");
            txtEls[1].classList.toggle("hide");
        }
    }
    let token = document.querySelector("[name='__csrf__token__']").value;
    dataToSend = new FormData();
    dataToSend.set('a', 'ulubione-ile');
    dataToSend.set('__csrf__token__', token);
    makeRequest('/a/?a=ulubione-ile', "GET", dataToSend, favouriteCountCallbackFunction);
}

function favouriteAdd(el) {
    el.addEventListener("click", function(e) {
  
        e.preventDefault();
        let id = el.getAttribute("data-id");
        let token = document.querySelector("[name='__csrf__token__']").value;
        let action = "ulubione-dodaj";
        if (el.classList.contains("btn--heartAvtive")) {
            action = "ulubione-usun";           
        }

        data = new FormData();
        data.set('a', action);
        data.set('produkt', id);
        data.set('__csrf__token__', token);
        makeRequest('/a/', "POST", data, favouriteCallbackFunction, el);

        if (el.classList.contains("js--removeProduct")) {
            el.closest(".product__item").remove();
        }

    })
}


Array.prototype.map.call(document.querySelectorAll(".btn--heart"), function(item) { favouriteAdd(item) });



//Newsletter: uzupełnienie wartości inputa w modalu

if (document.getElementById("newsletterBtn")) {
    function newsletterCallbackFunction(data) {
        document.getElementById("checbox-ajax").innerText = data;

    }

    document.getElementById("newsletterBtn").addEventListener("click", function() {
        document.getElementById("newsletterEmailModal").value = document.getElementById("newsletterEmail").value;
        if (!this.classList.contains("j2--click")) {
            this.classList.add("j2--click");
            data = new FormData();
            let token = document.querySelector("[name='__csrf__token__']").value;
            data.set('__csrf__token__', token);
            makeRequest('/a/?a=newsletter-zgoda', "GET", data, newsletterCallbackFunction);

        }

    })


    document.getElementById("newsletterEmail").addEventListener("keypress", function(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            document.getElementById("newsletterBtn").click();
        }
    })

}






function cartBoxClose(btn) {

    document.querySelector(".header__cart-box").classList.remove("open");
    btn.classList.remove("active");

    document.querySelector("#box-close-cart").removeEventListener("click", function() {}, false)
    document.querySelector("#box-close-cart").remove();

    document.querySelector("body").classList.remove("overflow--hidden");
    document.querySelector(".header__cart-box .alert-success").style.display = "none";
}

if (document.getElementById("headerControls__cartBtn")) {

    // let cartBtn = document.getElementById("headerControls__cartBtn");
    // cartBtn.addEventListener("click", function(e) {
    //     e.preventDefault();

    //     if (cartBtn.classList.contains("active")) {
    //         cartBoxClose(cartBtn);
    //     } else {
    //         closeLoginBox();
    //         document.querySelector(".header__cart-box").classList.add("open");
    //         cartBtn.classList.add("active");
    //         document.querySelector("body").classList.add("overflow--hidden");
    //         let box = document.createElement("span");
    //         box.className = "open-menu-shadow";
    //         box.id = ("box-close-cart");
    //         document.querySelector("body").appendChild(box);
    //         document.querySelector("#box-close-cart").addEventListener("click", function() {
    //             console.log("ccc22");
    //             cartBoxClose(cartBtn);
    //         })

    //     }

    //     if (document.querySelector(".nav-toggle").classList.contains("open")) {
    //         document.querySelector(".nav-toggle").click();
    //     }
    // })


    // document.querySelector(".btn-close-cart").addEventListener("click", function() {
    //     cartBoxClose(cartBtn);
    // })
    // document.querySelector(".btn-close-cart2").addEventListener("click", function() {
    //     cartBoxClose(cartBtn);
    // })


}




function createSimpleElemnts(list, length, wrapper, classN) {
    let newUl = document.createElement("ul");
    newUl.className = "listProduct__wrapper row row--16";
    wrapper.prepend(newUl);
    let time = 0;
    let products = list;
    for (i = 0; i < length; i++) {
        let product = products[i];
        time += 80;

        let elLi = document.createElement("li");
        elLi.className = classN;

        let elA = document.createElement("a");
        elA.className = "product__link";
        elA.href = product.url;

        let elFigure = document.createElement("figure");
        elFigure.className = "product__figure";
        if (product.zdjecie) {
            let elImage = document.createElement("img");
            elImage.src = product.zdjecie;
            elImage.alt = product.nazwa;
            elFigure.appendChild(elImage);
        }


        let elSpan = document.createElement("span");
        elSpan.classList = "product__brand";

        if (product.producent_nazwa) {
            elSpan.textContent = product.producent_nazwa;

        }

        let elh3 = document.createElement("h3");
        elh3.className = "product__title";
        elh3.textContent = product.nazwa;

        let elPrB = document.createElement("div");
        elPrB.classList = "product__bottom";

        if (product.procent) {
            let elDiscount = document.createElement("span");
            elDiscount.className = "product__discount";
            elDiscount.textContent = "-" + product.procent + "%";
            elPrB.appendChild(elDiscount);
        }

        if (product.cena_poprzednia)

        {
            let elPriceOld = document.createElement("span");
            elPriceOld.className = "product__priceOld";
            let oldPrice = (product.cena_poprzednia).replace(".", ",");
            elPriceOld.textContent = oldPrice + " zł";
            elPrB.appendChild(elPriceOld);

        }



        let elPriceNew = document.createElement("span");
        elPriceNew.className = "product__price product__priceNew";
        let newPrice = (product.cena).replace(".", ",");
        elPriceNew.textContent = newPrice + " zł";
        elPrB.appendChild(elPriceNew);

        let elBtnWr = document.createElement("div");
        elBtnWr.className = "heart__wrapper";


        if (product.cenaMinimum) {
            let elPriceMin = document.createElement("span");
            elPriceMin.className = "product__priceMin";
            let minPrice = (product.cenaMinimum).replace(".", ",");
            elPriceMin.textContent = "Poprzednia najniższa cena: " + minPrice + " zł";
            elPrB.appendChild(elPriceMin);
        }


        let elBtn = document.createElement("button");
        elBtn.className = "btn--heart";
        elBtn.id = product.id;
        elBtn.setAttribute("data-id", product.id);
       
        if (product.ulubione == "1") {
            elBtn.className = "btn--heart btn--heartAvtive";
            elBtn.setAttribute("aria-label", "Usuń z ulubionych");
        }else{
            elBtn.setAttribute("aria-label", "Dodaj do ulubionych");
        }

        elBtnWr.appendChild(elBtn);


        elA.appendChild(elFigure);
        elA.appendChild(elSpan);
        elA.appendChild(elh3);

        elA.appendChild(elPrB);

        elLi.appendChild(elBtnWr);

        elLi.appendChild(elA);

        newUl.appendChild(elLi);
        setTimeout(function() {
            elLi.classList.remove("product__item--op0");
        }, time);
    }
    Array.prototype.map.call(wrapper.querySelectorAll(".btn--heart"), function(item) { favouriteAdd(item) });
}




//dodawanie produktów do tab-strona główna



function createTabElements(data, el) {

    let id = el.getAttribute("aria-controls");
    let wrapper = document.getElementById(id);

    let products = JSON.parse(data);

    if (products.length > 0) {


        createSimpleElemnts(products, 5, wrapper, "col-6 col-xl-20 product__item product__item--op0");





    } else {
        if (wrapper.querySelector(".btn-all-cat")) { wrapper.querySelector(".btn-all-cat").remove(); }
        let noEl = document.createElement("ul");
        noEl.className = "alert alert-grey";
        noEl.textContent = "W wybranej przez Ciebie kategorii nie ma jeszcze produktów";
        wrapper.prepend(noEl);
    }


}

if (document.querySelector(".tabs__nav-trigger")) {
    let tabBtns = document.querySelectorAll(".tabs__nav-trigger");
    for (var i = 0; i < tabBtns.length; i++) {
        tabBtns[i].addEventListener("click", function(e) {
            if (!this.classList.contains("js--add")) {
                let el = this;
                let category = this.getAttribute("data-category");
                let token = document.querySelector("[name='__csrf__token__']").value;

                dataToSend = new FormData();
                dataToSend.set('__csrf__token__', token);
                makeRequest('/a/?kategoria=' + category, "GET", dataToSend, createTabElements, el);
                this.classList.add("js--add");

            }
        });
    }


    document.querySelector(".tabs__nav-trigger.tabs__nav-trigger-1").click();
}


//rozwijana lista produktów
if (document.querySelector(".listProduct__btn")) {
    document.querySelector(".listProduct__btn").addEventListener("click", function() {
        if (this.classList.contains("open")) {
            this.classList.remove("open");
            slideUp(document.getElementById("form__nav-left"));
        } else {
            this.classList.add("open");
            slideDown(document.getElementById("form__nav-left"));
        }
        const spans = this.querySelectorAll("span");
        for (var i = 0; i < spans.length; i++) {
            spans[i].classList.toggle("show");

        }
    })
}






//lista elemetów w katalogu
function createListElements(data, el) {

    // let id = el.getAttribute("aria-controls");

    let wrapper = document.querySelector(".listProduct__right");

    wrapper.innerHTML = "";

    if (document.querySelector(".alert")) {
        document.querySelector(".alert").remove();
    }

    let products = JSON.parse(data);


    if (products.length > 0) {
        createSimpleElemnts(products, products.length, wrapper, "col-6 col-xl-4 product__item product__item--op0");

        if (window.scrollY > 600 & window.innerWidth>992) {
   
            document.querySelector(".listProduct__right").scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
    } else {
        // if (wrapper.querySelector(".btn-all-cat")) { wrapper.querySelector(".btn-all-cat").remove(); }
        let noEl = document.createElement("div");
        noEl.className = "alert alert-grey";
        noEl.textContent = "W wybranej przez Ciebie kategorii nie ma jeszcze produktów";
        wrapper.prepend(noEl);

        if (window.scrollY > 600 & window.innerWidth>992) {
   
            document.querySelector(".alert").scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
    }
}



if (document.querySelector("#form__nav-left")) {
    let tabBtns = document.querySelectorAll("#filtrSubmit");
    for (var i = 0; i < tabBtns.length; i++) {
        tabBtns[i].addEventListener("click", function(e) {
            e.preventDefault();
            let el = this;
            // let category = this.getAttribute("data-category");
            let token = document.querySelector("[name='__csrf__token__']").value;
            let form = document.querySelector("#form__nav-left");
            let katId=document.querySelector(".listProduct__wrapper").getAttribute("data-kategoriaId");
            let dataToSend = new FormData(form);

            dataToSend.set('__csrf__token__', token);
            dataToSend.set('kategoria', katId);
            let txt = "";
            for (const [key, value] of dataToSend) {
                txt += "&" + `${key}=${value}`;
            }
          
            makeRequest('/a/?akcja=katalog' + txt, "GET", dataToSend, createListElements, el);

            let btnMobile=document.querySelector(".listProduct__btn");
            if(btnMobile.classList.contains("open")){
                btnMobile.click();
            }


        });
    }

}